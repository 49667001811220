'use strict';

angular.module('kljDigitalLibraryApp')
    .service('savedSearchesService', ["$q", "common", "appConfig",
        function($q, common, appConfig) {

            var baseUrl = '/api/users';

            this.getSavedSearchesData = function(payload) {


                return common.callApi('POST',
                        baseUrl + '/get/savedSearchData',
                        appConfig.EMPTY_STRING,
                        appConfig.CONTENT_TYPE,
                        payload
                    )
                    .then(function(response) {
                        // console.log(response, "=========");
                        return (response.data);
                    })
                    .catch(function(err) {
                        console.log(err, "==========")
                        return (err.data);
                    });

            }

            this.removeSavedSearch = function(id) {

                var deferred = $q.defer();

                common.callApi('DELETE',
                    baseUrl + '/delete/searchInfo/' + id,
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE
                )

                .then(function(response) {
                    deferred.resolve();
                })

                .catch(function(error) {

                    deferred.reject(error);
                });

                return deferred.promise;
            }

            this.save = function(payload) {

                var deferred = $q.defer();
                common.callApi('POST',
                    baseUrl + '/save/searchInfo',
                    appConfig.EMPTY_STRING,
                    appConfig.CONTENT_TYPE,
                    payload
                )

                .then(function(response) {
                        deferred.resolve(response.data);
                    })
                    .catch(function(error) {

                        deferred.reject(error);
                    });

                return deferred.promise;
            }
        }
    ]);